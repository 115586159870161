export const USER_FIRST_NAME = "firstName";
export const USER_LAST_NAME = "lastName";
export const USER_NICK = "nick";
export const USER_EMAIL = "email";
export const USER_ACCEPTANCE_YEAR = "acceptanceYear";
export const USER_ID = "id";
export const USER_CID = "cid";
export const USER_AGREEMENT = "userAgreement";
export const USER_PASSWORD = "password";
export const USER_LANGUAGE = "language";
export const USER_GROUPS = "groups";
export const USER_PHONE = "phone";
export const USER_RELATIONSHIPS = "relationships";
