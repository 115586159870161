export const SG_ID = "id";
export const SG_NAME = "name";
export const SG_PRETTY_NAME = "prettyName";
export const SG_TYPE = "type";
export const SG_TYPE_SOCIETY = "SOCIETY";
export const SG_TYPE_COMMITTEE = "COMMITTEE";
export const SG_TYPE_BOARD = "BOARD";
export const SG_TYPE_ADMIN = "ADMIN";
export const SG_TYPE_ALUMNI = "ALUMNI";
export const SG_TYPE_FUNCTIONARIES = "FUNCTIONARIES";
export const SG_EMAIL = "email";
